$transparent-bg: #ffffffab;
$welcome-bg: #ffffff99;
$navbar-bg: #406098;
$button-color: #D64309;
$mobile-max-width: 600px;

.gray-title {
    background-color: #E6ECF9;
    text-align: center;
    margin: auto;
    justify-content: center;
    width: 80%;
    max-width: 70rem;
    padding: 2rem 0;
    border-bottom: 3px solid rgb(196, 196, 196);
}
@media only screen and (max-width: $mobile-max-width) {
    .gray-title {
        width: 100%;
    }
    .food-dropdown .dropdown-toggle {
        background-color: #FFFFFF !important;
        width: fit-content;
    }
    .btn:hover {
        color: rgb(112, 112, 112) !important;
    }
    .date-inputs {
        width: 60%;
    }

    .gray-title {
        background-color: white;
    }
}

.large-button {
    border: none;
    background-color: $button-color;
    color: white;
    margin: 0 auto 2rem auto;
    padding: .5rem;
    white-space: nowrap;
    text-overflow: clip;
    width: 80%;
    border-radius: .3rem;
    font-size: larger;
    font-weight: bold;
    box-shadow: 1px 1px 2px 1px #40404083;
}

.event-overview-title {
    font-weight: 600;
    width: 100%;
    border-bottom: 2px solid rgb(148, 148, 148);
    margin: 2rem 0 .5rem 0;
}

.event-container {
    width: 100%;
    padding: 5px;
    margin: 1rem auto;
    background-color: #E6ECF9;
    border-radius: .3rem;
}
@media only screen and (max-width: $mobile-max-width) {
    .event-container {
        background-color: white;
        padding: 0 .0 .8rem 0;
    }
}


.event-info {
    padding: .5rem;
}

.event-title {
    font-size: 24px;
    display: block;
    width: 18rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.event-icon-container {
    margin: .7rem 0 0 auto;
    margin-right: 1rem;
}
.event-icon {
    border-radius: .3rem;
    width: 2rem;
    height: auto;
    padding: .3rem;
    background-color: $button-color;
    margin: 0 .3rem;
}

.create-event-btn {
    background-color: $button-color !important;
    color: white;
    border-radius: 10px !important;
    padding: 0.4em !important;
    padding-left: 1.2em !important;
    padding-right: 1.3em !important;
    font-weight: 600 !important;
    margin: 1rem 1rem 0 0 !important;
    border: none !important;
    height: fit-content !important;
}

.create-event-btn:active {
    background-color: #b63a08 !important;

}

.publish-btn {
    max-width: 10rem;
}


.food-dropdown .dropdown-toggle {
    background-color: transparent;
    border: 1px solid #878787;
    color: rgb(112, 112, 112);
    font-weight: 400 !important;
    margin-top: 1rem;
    width: fit-content;
    text-align: left;
}

.date-inputs{
    width: 90%;
}

.live {
    background-color: #76fa81;
    padding: .5rem;
    border-radius: .5rem;
    font-weight: 600;
    margin: 0 1rem;
}

.not-live {
    background-color: #dbdbdb;
    padding: .5rem;
    border-radius: .5rem;
    font-weight: 600;
    margin: 0 1rem;
}