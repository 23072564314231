$transparent-bg: #ffffffab;
$welcome-bg: #ffffff99;
$navbar-bg: #406098;
$button-color: #D64309;
$mobile-max-width: 600px;

//!important is being used here to override bootstrap's !important
.root-container .mb-3 {
    margin-bottom: 0 !important;
    background-color: $navbar-bg !important;
}


.login-paragraph {
    margin: 1.5rem 0 .5rem 0;
    font-weight: 600;
    min-width: 200px;
}

.rounded-pill.btn.btn-primary {
    border: none;
    background-color: $button-color;
    padding: auto;
    color: white;
    border: 0;
    margin-top: 10px;
    margin: auto;
    white-space: nowrap;
    text-overflow: clip;
    box-shadow: 1px 1px 2px 1px #40404083;
}

.btn.btn-primary {
    font-weight: 600 !important;
}

.button-forgot-password.btn-light {
    color: black;
    background-color: white;
    font-size: 11px;
    padding: 0 24px;
    white-space: nowrap;
    text-overflow: clip;
    margin: 0 9.5rem 2rem 0;
    font-weight: 600;
}

.overflow {
    overflow: hidden;
}

.activity-prompt {
    max-width:85%;
}

.lm-activity-title {
    color: gray;
    padding: .3rem;
    margin-left: .5rem;
}

#button-welcome {
    padding: 8px 0px;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 20rem;
    max-width: 600px;
}
@media only screen and (max-width: $mobile-max-width) {
    #button-welcome {
        width: 70%;
        margin-top: 5%;
    }
}



#button-sign-in {
    padding: 8px 48px;
    margin: 16px 0 24px;
}
#button-Save {
    min-width: 75px;
    padding: 8px;
    margin: 12px 0 24px;
    text-align: center;
}

#button-create-account {
    padding: 8px 50px;
    margin-bottom: 1.5rem;
}

.login-control.form-control {
    font-size: 12px;
    padding: 24px 8px 10px 12px;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #49454F;
    margin: 0;
    width: 18rem;
    margin-top: 2.5rem;
    margin: 2rem auto .5rem auto;
}

.login-label.form-label {
    font-size: 12px;
    position: absolute;
    top: 2.3rem;
    left: .75rem;
    color: black;
    font-weight: 600;
}

.input-container {
    position: relative;
    display: inline-block;
}

.navbar {
    background-color: $navbar-bg;
    color: white;
}

.navbar-item {
    color: white;
    font-weight: bold;
    margin-right: 2rem;
}


.welcome-title {
    padding-bottom: 32px;
    font-weight: 700;
}

.welcome-text {
    font-size: 20px;
    font-weight: 500;
}
@media only screen and (max-width: $mobile-max-width) {
    .welcome-text {
        font-size: 17px;
        font-weight: 500;
    }
}

.login-title {
    font-weight: bold;
}

.root-container {
    background-image: url("../Images/Background.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #8f8eba; //This color will be displayed while the image is loading
    min-height: 100vh;
}

.main-container {
    background-color: white;
    width: 80%;
    height: 100%;
    margin-bottom: 0px;
    padding: 32px;
    margin: auto;
    max-width: 70rem;
    min-height: 100vh;
    clear: both;
}
@media only screen and (max-width: $mobile-max-width) {
    .main-container {
        width: 100%;
        background-color: $transparent-bg;
    }
}

.login-control::placeholder {
    font-size: 16px;
}

.login-container.text-center.container {
    background-color: $transparent-bg;
    border-radius: 27px;
    padding: 32px 32px;
    margin: 30px auto;
    margin-bottom: 0px;
    width: 30rem;
}
@media only screen and (max-width: $mobile-max-width), (max-height: 650px) {
    .login-container.text-center.container {
        max-width: none;
        width: 100%;
        min-height: 100vh;
        border-radius: 0px;
        margin: auto;
    }
}

body, html {
    height: 100%;
}

.main-container.mx-auto.d-flex.flex-column{
    background-color: $welcome-bg;
    margin-bottom: px;
}

.Page-Title{
    font-weight: 700;
    margin-bottom: 1rem;
}

.Page-Text{
    font-size: 20px;
    font-weight: 500;
}

.page-Floating-Label{
    font-size: 16px;
    font-weight: 400;
}

.page-Floating-Label.activity-instructions {
    margin-top: 2.5rem;
}

.page-Form-Input.form-control{
    font-size: 20px;
    font-weight: 400;
}

div.page-Form{
    margin-bottom: 20px;
}

.page-Map.container{
    width: 50%;
    height: 350px;
    border: none;
    border: 1px solid grey;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid black;
}


.line-container {
    width: 100%;
    padding: 3px;
    margin: 1rem auto;
    border-bottom: 2px solid black;
}

.tour-title {
    font-size: 24px;
    display: block;
    width: 18rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.author {
    color: #79747E;
    font-size: 16px;
}

.icon-container {
    margin-top: 1rem;
}

.icon {
    border-radius: 4px;
    width: 2.2rem;
    height: auto;
    margin: 0 .2rem 0 0;
}

.line-info {
    margin: 0 auto .5rem 0;
}

@media only screen and (max-width: $mobile-max-width) {
    .icon-container  {
        margin: .3rem 0 .2rem auto;
        
    }
    .line-container {
        background-color: white;
        border-radius: 4px 4px 0 0;
        padding: .5rem;
        border-bottom: 2px solid rgb(81, 81, 81);
    }
}
@media (min-width: 407px) and (max-width: $mobile-max-width) {
    .icon-container  {
        margin: 1rem 0 0 0;
    }
}


.container-dashboard {
    margin-top: 12%;
    max-width: 80%;
}

.image-dashboard {
    max-width: 80%;
}

.button-dashboard.btn.btn-primary {
    border: none;
    background-color: $button-color;
    color: white;
    border: 0;
    margin: 12px;
    padding: 18px;
    border-radius: 27px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: 2px 2px 4px 2px #22222283;
}

.btn.btn-primary:active {
    //to override bootstrap's default blue background
    background-color: #ac3100 !important;
}
.btn.btn-primary.icon-button:active {
    background-color: transparent !important;
}

.text-dashboard {
    font-size: 32px;
    font-weight: 700;
    margin-top: 5%;
    text-shadow: 2px 2px 2px #22222283;
}

.my-landmarks {
    margin-top: 5%;
}

.icon-button {
    all: unset !important;
}

.tour-marker {
    display: block;
    background-color: #E6ECF9;
    margin: .2rem;
    padding: .5rem;
    font-size: 18px;
}

.tour-map {
    width: 100%;
    height: 30rem;
    margin: 0 0 5rem 0;
}
#map-container {
    height: 100%;
    width: 100%;
}



.section-header {
    margin: 1rem 0 1rem 0;
    font-weight: 600;
}

.map-header {
    font-weight: 600;
    font-size: 28px;
    position: relative;
    top: 3.6rem;
    z-index: 1;
    background-color: white;
    padding: .5rem;
    max-width: fit-content;
    text-align: center;
    box-shadow: 2px 2px 4px 0 #22222283;
    border-radius: 0 0 4px 0;
    white-space: nowrap;
}
@media only screen and (max-width: $mobile-max-width) {
    .tour-map  {
        height: 20rem;
    }
    .map-header {
        font-size: 18px;
        max-width: fit-content;
        top: 2.85rem;
        background-color: #ffffffe7;
    }
}

.tour-options {
    margin-top: 1rem;
    float: right;
}

.tour-button {
    margin-right: 1rem !important;
}
 
@media only screen and (max-width: $mobile-max-width) {
    .tour-marker  {
        background-color: white;
        border-radius: 4px;
    }
}

#account-container {
    margin: 0 auto;
    width: 65%;
}

.account-label.form-label {
    position: absolute;
    top: -.8rem;
    left: .5rem;
    font-size: 12px;
    background-color: white;
    padding: .2rem;
    border-radius: 4px;
    font-weight: 500;
}

.account-input-container {
    position: relative;
}

.form-input.account-info {
    margin-top: 1.5rem;
    height: 3rem;
}

#save-changes {
    margin-top: 2rem;
}

#account-title {
    margin-bottom: 3rem;
}

h4.section-header {
    margin-top: 3rem;

}

@media only screen and (max-width: $mobile-max-width) {
    #account-container {
        margin: 0;
        width: 100%;
    }
    .account-label.form-label {
        top: -.1rem;
        left: .5rem;
        font-size: 10px;
        background-color: transparent;
        font-weight: 600;
    }
    .form-input.account-info {
        padding: .7rem 0 0 .6rem;
    }
}


#create-account .login-control {
    margin: 1rem 0 0 0;
}
#create-account .rounded-pill.btn.btn-primary {
    margin: 2rem 1rem 0 1rem;
}
#create-account .login-label {
    top: 1.3rem;
    left: .75rem;
}

#reset-btns .rounded-pill.btn.btn-primary {
    margin: 2rem 1rem 0 1rem;
}

.create-landmark .map-header {
    max-width: fit-content;
}
.create-landmark .tour-map {
    margin-bottom: 5rem;
}
.create-landmark .rounded-pill.btn.btn-primary {
    margin: 2rem auto 0 auto;
    width: 6rem;
    margin-bottom: 8rem;
}

.create-landmark .account-input-container {
    margin: 0;
}

.media-upload-container .form-control {
    width: fit-content;
    display: inline;
}
.media-upload-container {
    text-align: center;
}

.media-upload-container .inlinefilebutton {
    background-color: white;
    padding: .35rem .2rem;
    border: 1px solid #d7d7d7c1;
    border-radius: 5px;
    color: black;
}

.max-size {
    margin-top: 1.5rem !important;
}

.media-upload {
    width: 41%;
    margin: 0 auto 0 0;
}





#inTour-locals {
    padding: .3rem;
}
#inTour-locals h3 {
    font-weight: 600;
}
#potential-locals {
    padding: .3rem;
}
#potential-locals h3 {
    font-weight: 600;
}

.create-tour-container {
    background-color: white;
    height: 100vh;
    max-width: 100% !important;
}
.create-tour-locals {
    float: left;
    width: 30%;
    padding: .3rem;
}
.create-tour-map {
    float: right;
    width: 70%;
    z-index: 1;
}
.locals-list-group span{
    margin: .4rem -.4rem 0 auto;
    border: none;
    border-radius: 12px;
    height: 2rem;
    width: 2rem;
}
.locals-list-group {
    display: block;
    background-color: #f0f0f0;
    margin-bottom: .3rem;
    padding: .5rem;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    width: 100%;
    text-align: left;
}
.create-tour-map .login-title {
    margin: .8rem .5rem;
    min-height: 3rem;
}
.create-tour-map .tour-map {
    height: 45rem;
    margin-bottom: 0;
}
.create-tour-map .google-map {
    margin: 0;
    padding: 0;
}


.tour-info {
    position: absolute;
    top: 4.5rem;
    right: 4rem;
}
.tour-info .campus-options .dropdown-toggle {
    position: relative;
    background-color: transparent;
    border: 1px solid #878787;
    color: rgb(112, 112, 112);
    font-weight: 500 !important;
    right: 13rem !important;
    bottom: 2.5rem;
}

.tour-info .tour-name  {
    margin: 0;
    height: 2.5rem;
}

.marker-label {
    padding:.3rem .5rem;
    text-align: center;
    background-color: #FF8989;
    border-radius: 50%;
    box-shadow: 1px 1px 2px 1px #40404083;
    font-weight: 700;
}

.landmark-label {
    background-color: #fcfcfc;
    padding: .2rem .3rem;
    border-radius: 10px;
}


.remove-inTour-btn {
    border: none;
    background-color: transparent;
    margin: 0 0 0 auto;
}

.inTour-list {
    display: flex;
    align-items: center;    
}   
.item-handle {
    cursor: grab;
    margin-right: .5rem;
}
.item-title {
    margin-top: .3rem;
}

.save-tour-btn {
    background-color: $button-color !important;
    color: white;
    position: relative !important;
    z-index: 1 !important;
    border-radius: 10px !important;
    padding: .5rem !important;
    font-weight: 600 !important;
    margin: 1rem 1rem 0 0 !important;
    float: right;
    border: none !important;
    height: fit-content !important;
}

.items-in-tour {
    margin-bottom: 2rem;
}

.view-landmark-activity {
    margin-bottom: 3rem;
}
.view-landmark-desc {
    margin-bottom: 1rem;
}

.delete-item {
    background-color: red;
    border: none;
    border-radius: 10px;
    margin: auto;
    color: white;
    padding: .3rem 1rem;
    font-weight: 600;
    font-size: larger;
}

.account-university-select {
    font-size: 12px;
    padding: 24px 8px 10px 12px;
    border: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #49454F;
    margin: 0;
    width: 18rem;
    margin-top: 2.5rem;
    margin: 2rem auto .5rem auto;
    background-color: var(--bs-body-bg);
}

.manage-landmarks {
    padding: 0.3em;
    margin: 0.8em 0.8em 0 0.8em;
    border: 0em;
    border-bottom: 0.15em !important;
    border-style: solid !important;
    border-color: #000000 !important;

    h6 {
        color: #a8a8a8;
    }
}
