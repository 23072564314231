.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 100px;

  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 4rem;
  }
  
  .pin-text {
    font-size: 1.3em;
  }
  