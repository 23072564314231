body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.updateCard {
  z-index: 10;
  position: absolute !important;
  width: 60%;
  top: 20%;
  left: 20%;
}

.cursor {
  cursor: pointer;
}

.Binded {
  overflow: auto;
  max-height: 60vh;
}

.searchCard {
  z-index: 10;
  position: absolute !important;
  width: 80%;
  top: 10%;
  left: 10%;
}

.icons-buttons-rip {
  width: 300px;
  height: 300px;
}

.icons-buttons {
  width: 30px;
  height: 30px;
  cursor: pointer;
  pointer-events: none
}

.backing {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.floatright {
  float: right !important;
}

.container {
  top: 0px;
  margin-top: 55px;
}

.container .title {
  text-align: center;
  padding: 5%;
}

.container .admins {
  margin-left: 5%;
  margin-right: 5%;
  background-color: #61dafb;
}

.admin-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 0px;
}

.admin-grid-container .grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.admin-grid-container.header .grid-item {
  background-color: grey !important;
  color: white !important;
}

.results-padding {
  height: calc(41px*4);
}

.wrap {
  width: 100%;
  height: 80%;
}

table.head tr td {
  background: #eee;
}

.inner_table {
  height: 200px;
  overflow-y: auto;
}

.spacer {
  padding-left: 30px;
}

.institution {
  color: #61dafb;
}

hr.spacer {
  margin-top: 0px;
  margin-bottom: 0px;
}

.hexcolor {
  width: 60% !important;
  display: inline-block !important;
}

.univgrid {
  display: grid !important;
  grid-template-columns: 33% 33% 33%;
  padding: 0px;
}

.univheader {
  justify-content: left !important;
}

.univtext {
  width: 100%;
}

.colorpicker {
  margin-bottom: 38px;
  height: 38px;
  width: 38px;
  outline: 0;
  transform: translateY(11px);
}

.container.loptions {
  display: flex;
  flex-flow: column;
  padding: 15px;
  background-color: gray;
  height: calc(100% - 55px) !important;
  /*110px for footer*/
  width: min(70%, 400px);
  position: absolute;
}

.container.loptions.null {
  left: 0px;
}

.container.loptions.open {
  animation-name: OptionOpen;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes OptionOpen {
  from {
    left: max(-70%, -400px);
  }

  to {
    left: 0%;
  }
}

.container.loptions.close {
  animation-name: OptionClose;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes OptionClose {
  from {
    left: 0%;
  }

  to {
    left: max(-70%, -400px);
  }
}

.InfoBorder {
  margin: 10px;
}

.loption.button {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: rotate(45deg);
  top: calc(50% - 35px);
  z-index: 999;
}

.loption.button.null {
  left: calc(min(70%, 400px) - 17px);
}

.form-padding {
  margin-top: 5px;
  margin-right: 10px !important;
}

.loption.button.open {
  animation-name: ButtonOpen;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes ButtonOpen {
  from {
    left: -17px;
  }

  to {
    left: calc(min(70%, 400px) - 17px);
  }
}

.loption.button.close {
  animation-name: ButtonClose;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes ButtonClose {
  from {
    left: calc(min(70%, 400px) - 17px);
  }

  to {
    left: -17px;
  }
}

.container.location {
  margin-top: 55px;
  margin-left: 0px;
  margin-right: 0px;
}

.map {
  height: 100%;
  width: 100%;
}

.map.null {
  width: calc(100% - min(70%, 400px)) !important;
}

.colorTitle {
  justify-content: left !important;
  align-items: left !important;
  display: block;
}

.map.open {
  animation-name: MapOpen;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes MapOpen {
  from {
    width: 100%;
  }

  to {
    width: calc(100% - min(70%, 400px));
  }
}

.map.close {
  animation-name: MapClose;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes MapClose {
  from {
    width: calc(100% - min(70%, 400px));
  }

  to {
    width: 100%;
  }
}

.HomeMap {
  /* top: 0px;
  left: 0px; */
}

.Dash {
  text-align: center;
}

.iconButtons {
  padding: 0px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.fade-10 {
  cursor: pointer;
  position: absolute !important;
  left: 50%;
  opacity: 98%;
  width: 75%;
  z-index: 999;
  transform: translate(-50%, -200%);
  animation-name: alertSweep;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  margin-top: 3rem;
  height: fit-content;
}

@keyframes alertSweep {
  from {
    transform: translate(-50%, -120%);
  }

  20% {
    transform: translate(-50%, 0%);
  }

  80% {
    transform: translate(-50%, 0%);
  }

  100% {
    transform: translate(-50%, -300%);
  }
}

.geoContainer {
  flex-grow: 8;
  width: 100%;
  height: auto;
  margin-top: 15px;
  overflow: auto;
}

.threeCol .col {
  width: 33% !important;
  display: inline-block
}

.inline {
  display: inline-block !important;
}

.submitForm {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
}

.AdminInline {
  display: inline-block !important;
  width: calc(25% - 80px) !important;
  margin-right: 20px;
}

.navbarDropdownAdjust div {
  transform: translate(-90px, -5px);
}

.mobileView {
  width: calc(9 / 16 * 70vh) !important;
  height: 70vh !important;
  margin-right: 30px;
  margin-left: 30px;
  background-color: #f2f2f2;
  overflow: hidden;
  position: relative;
}

.mobileView div {
  color: white;
}

.mobileView div.mobileHeader {
  width: 100%;
  height: 8%;
}

.mobileView .mobileTitle {
  color: black;
  text-align: center;
  font-size: 5vh;
  margin: 5px;
  overflow: hidden;
}

.mobileViewer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.backgroundColor {
  width: 100%;
  height: 100%;
  position: absolute;
}

.mobileViewButtons {
  height: calc(70vh / 15);
  width: 75%;
  font-size: calc(70vh / 36);
  position: "relative";
  padding-top: calc(70vh / 90);
  border-radius: 7px;
  margin-bottom: calc(1vh);
  overflow: hidden;
}

.mobileViewButtons.switch {
  font-size: calc(70vh / 45);
  padding-top: calc(70vh / 60);
  padding-left: 5%;
  text-align: left !important;
}

.mobileSwitch {
  height: 80%;
  width: 20%;
  float: right;
  margin-right: 5%;
  transform: translateY(-2px);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mobileSwitch div {
  height: 100%;
  width: 55%;
  border-radius: 100%;
}

.mobileBackgroundImage {
  position: absolute;
  z-index: -14;
}

.mobileFooter {
  position: absolute;
  width: 100%;
  height: 8%;
  bottom: 0px;
}

.mobileButton {
  width: 25%;
  height: 100%;
  display: inline-block;
  padding: 2%;
}

.mobileButton center {
  color: white;
}

